<template>
  <div @keydown="onChange" @click="onChange" @change="onChange">
    <CRow v-if="!index" class="no-gutters">
      <CCol col="3" />
      <CCol col="5">{{ $t('Modules') }}</CCol>
      <CCol col="3">{{ $t('Monthly value') }}</CCol>
      <CCol col="1" />
    </CRow>
    <CRow class="no-gutters">
      <CCol col="3" />
      <CCol col="5">
        <AMultiSelect :disabled="disabled" :horizontal="{ input: 'col-sm-12' }" :name="`modules.$each.${index}.property_module_id`" v-model="state.property_module_id" :options="available_modules" :isValid="isValid('property_module_id')" :errors="getErrors('property_module_id')" />
      </CCol>
      <CCol col="3">
        <AInput :disabled="disabled" :horizontal="{ input: 'col-sm-12' }" type="number" :name="`modules.$each.${index}.value`" v-model="state.value" placeholder="Value" :isValid="isValid('value')" :errors="getErrors('value')" />
      </CCol>
      <CCol col="1">
        <button :disabled="disabled" type="button" role="button" class="input-group-text" @click="onRemove"><CIcon name="cil-x" /></button>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: 'BuildingModule',
  props: {
    module: {
      type: Object,
      required: true
    },
    validation: {
      type: Array,
      default: () => ['', false, false]
    },
    index: {
      type: Number,
      required: true
    },
    lists: {
      type: Object,
      required: true
    },
    used: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean
    }
  },
  watch: {
    module(val) {
      this.state = val
    }
  },
  computed: {
    available_modules() {
      const used = this.used.filter(item => item !== this.state.property_module_id)
      const modules = (this.lists.modules || []).filter(item => used.indexOf(item.id) === -1)
      return modules
    }
  },
  data() {
    return {
      state: {} // ...this.module }
    }
  },
  mounted() {
    this.state = this.module
  },
  methods: {
    onSubmit(e) {
      //console.log('SUBMIT')
    },
    onChange(e) {
      this.$emit('moduleChanged', this.state, e)
    },
    onRemove() {
      this.$emit('removeModule', this.index)
    },
    isValid(field) {
      if (!this.validation[1]) return true
      return this.validation[1](this.validation[0] + field)
    },
    getErrors(field) {
      if (!this.validation[2]) return []
      return this.validation[2](this.validation[0] + field)
    }
  }
}
</script>

<style lang="scss" scoped>
.input-group-text {
  height: calc(1.5em + 0.75rem + 2px);
}
</style>
