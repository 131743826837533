<template>
  <CRow>
    <CCol col="12">
      <CAlert :color="status.class || 'danger'" :show="!status.is_valid && !!status.message" closeButton>
        <p v-html="status.message" />
      </CAlert>

      <CCard no-header :accentColor="isFormDirty ? (isFormValid ? 'success' : 'danger') : ''">
        <CCardBody>
          <h3>{{ form.id ? $t('Edit budget') + ` id: ${form.id}` : $t('Create budget') }}</h3>
          <CForm autocomplete="off" @keydown="clearErrors($event.target.name || 'test')">
            <ACard title="Basic Information">
              <AInput label="Name" v-model="form.name" :isValid="isValid('name')" :errors="getErrors('name')" />
              <AMultiSelect :disabled="!!(conditions.months_locked || conditions.disable_start)" :horizontal="{ input: 'col-sm-3' }" name="init_month_id" label="Init month" v-model="form.init_month_id" :options="lists.months" :isValid="isValid('init_month_id')" :errors="getErrors('init_month_id')" @select="value => onMonthChanged('init', value)" />
              <AMultiSelect :disabled="conditions.months_locked" :horizontal="{ input: 'col-sm-3' }" name="end_month_id" label="End month" v-model="form.end_month_id" :options="end_month_ids" :isValid="isValid('end_month_id')" :errors="getErrors('end_month_id')" @select="value => onMonthChanged('end', value)" />
              <ACheckBox label="Reserva legal" placeholder="Activar para calcular de manera independiente la Reserva Legal del 1%." v-model="form.has_legal_reserve" />
            </ACard>

            <ACard title="Modules">
              <div v-for="(module, index) of form.modules" :key="index">
                <transition name="fade">
                  <Modules ref="budget_module" :module="module" :disabled="conditions.disable_values" :index="index" :lists="lists" :used="used_modules" :validation="[`modules.$each.${index}.`, isValid, getErrors]" @moduleChanged="value => $set(form.modules, index, value)" @removeModule="removeModule(index, module)" />
                </transition>
              </div>
              <CCardFooter>
                <CButton :disabled="conditions.disable_values || !can_add_module" color="primary" class="pull-right" @click="addModule">{{ '+ ' + $t('Add module') }}</CButton>
              </CCardFooter>
            </ACard>

            <!--CTextarea :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }" :label="$t('Description')" v-model="form.description" /-->
            <ATextArea label="Description" v-model="form.description" />
          </CForm>
          <CCardFooter>
            <CButton class="mr-2" color="info" :disabled="!status.is_valid" @click="submit">{{ $t('Save') }}</CButton>
            <CButton color="secondary" @click="goBack">{{ $t('Back') }}</CButton>
          </CCardFooter>
        </CCardBody>
      </CCard>
    </CCol>

    <!-- // TODO: Debug view... create a global component -->
    <CCol v-if="DEBUG" col="12">
      <pre>{{ form }}</pre>
      <div class="summary text-red" v-if="$v.form.$error">
        Form has errors
        <pre>{{ showErrors }}</pre>
      </div>
    </CCol>
  </CRow>
</template>

<script>
//import * as utils from '@/app/_utils/global-utils'
import formMixin from '@/app/views/_mixins/form-mixin'

import Modules from './elements/Modules'

import { DEBUG } from '@/config/config'

export default {
  name: 'BudgetForm',
  mixins: [formMixin],
  components: {
    Modules
  },
  props: {
    caption: {
      type: String,
      default: 'Budget id'
    }
  },
  computed: {
    /*disable_start() {
      return false
    },
    init_month_id() {
      return this.init_month_ids[0]
    },
    init_month_ids() {
      let months = this.lists.months
      if (this.form.init && this.form.init.end_month_id) {
        months = this.lists.months.filter(item => parseInt(item.id) > parseInt(this.form.init.end_month_id))
      }
      return months
    },*/
    end_month_ids() {
      if (!this.form.init_month_id) return []
      const end_month = this.property._periods?._period && this.property._periods._period > this.form.init_month_id ? this.property._periods._period : this.form.init_month_id
      return this.lists.months.filter(item => parseInt(item.id) >= parseInt(end_month) || parseInt(item.id) === parseInt(this.form.end_month_id))
    },

    // Modules
    can_add_module() {
      if (!this.available_modules.length) return false
      if (this.form.id && this.property._periods?._period && this.property._periods._period >= this.form.init_month_id) return false
      let valid = true
      for (const module of this.form.modules) {
        const value = parseFloat('0' + (module.value || 0))
        if (!module.property_module_id || value === 0) valid = false
      }
      return valid
    },
    available_modules() {
      const used = []
      this.form.modules.map(item => used.push(item.property_module_id))
      const modules = (this.lists.modules || []).filter(item => used.indexOf(item.id) === -1)
      return modules
    },
    used_modules() {
      const used = []
      this.form.modules.map(item => used.push(item.property_module_id))
      return used
    }
  },

  data: () => {
    return {
      DEBUG: DEBUG, // TODO: Global or in Helper
      TYPE_FAC: 'FAC',
      TYPE_REC: 'REC',

      // DATA
      form: { id: 0, modules: [] },

      // Extra
      lists: {},

      // Helpers
      status: {
        message: false,
        is_valid: false
      },
      budgets: [],
      objects: {},
      conditions: {},
      validators: {}
    }
  },
  created() {
    this.form.id = this.$route.params.id || 0
    this.getData()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getData() {
      const self = this
      self.$http
        .get('admin/budgets' + (self.form.id ? `/${self.form.id}/edit` : '/create'))
        .then(response => {
          self.parseData(response.data)
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error.`, 'danger')
          //self.goBack() // TODO: Alert
          //self.$router.push({ path: 'login' })
        })
    },
    onMonthChanged(type, month) {
      console.log(type, month)
    },

    // Parse Extra and Related data
    parseData(data) {
      this.form = data.budget ? data.budget : { id: 0 }
      this.lists = data._lists || {}
      this.budgets = data.budgets
      this.setObjects()

      this.parseView()
      this.parseBefore(data._before)
      this.parseMonths()
      this.parseModules()
      this.parseConditions()

      this.validators = {
        main: data._validation || {},
        extra: data._extra_validation || {}
      }

      if (_.isEmpty(this.$v.$form)) this.parseValidator(data._validation, data._messages, true)
    },

    // Parsing
    parseView() {
      const options = [
        { click: this.submit, class: 'mr-2 btn-info', content: this.$t('Save'), disabled: !this.status.is_valid },
        { click: this.goBack, class: 'btn-secondary', content: this.$t('Back') }
      ]
      this.$store.dispatch('setTopActions', { [(this.form.id ? 'Edit' : 'Create') + 'Budget']: options })
    },

    // Helpers
    setObjects() {
      this.objects.modules = {}
      for (const module of this.lists.modules || []) {
        this.objects.modules[module.id] = module
      }
    },
    parseBefore(before) {
      let valid = true

      // Validate shares
      if (before && before.shares) {
        let total = 0
        let message = ''
        const shares = before.shares
        const precision = shares.precision ? shares.precision : 1

        for (const module of shares.modules) {
          const sum = parseInt('' + Math.round(module.sum * precision))
          const diff = Math.abs(100 * precision - sum)
          total += module.sum
          if (!sum || diff / precision > parseFloat(before.shares.delta)) {
            valid = false
            message +=
              `${this.$t('Module')}: <b>${this.objects.modules[module.module_id].name}</b> ` +
              this.$t(before.shares.message)
                .replace('%1', sum / precision)
                .replace('%2', parseFloat(before.shares.delta)) +
              '<br/>'
          }
        }
        if (!total) valid = false
        this.status.message = message || this.$t('There is no shares data to process.')
      }
      this.status.is_valid = valid
      this.parseView()
    },
    parseMonths() {
      this.conditions.disable_start = !!this.form.id
      if (!this.form.id && this.form.init && this.form.init.end_month_id) {
        const months = this.lists.months.filter(item => parseInt(item.id) > parseInt(this.form.init.end_month_id)) // TODO: use function
        this.form.init_month_id = months[0].id
      }
    },
    parseModules() {
      if (!this.form.modules.length) this.addModule()
    },
    parseConditions() {
      this.conditions = {
        disable_start: !!this.form.id || (this.form.init && this.form.init.end_month_id)
      }

      // TODO: Check this
      if (this.form.id && this.budgets) {
        let latest = false
        const current = this.budgets.find(item => item.id === this.form.id)
        for (const budget of this.budgets.filter(item => item.id !== this.form.id)) {
          if (budget.init_month_id > latest) latest = budget.init_month_id
        }

        if (latest > this.form.init_month_id) {
          this.conditions.months_locked = true
        }

        if (current) {
          for (const period_closing of current.periods_closing || []) {
            if (period_closing.period_type_id === this.TYPE_FAC && period_closing.status === 'C') this.conditions.disable_values = true
          }
        }
      }

      //if (this.form.init_month_id == this.property._periods?._period && this.property._periods?._status === 'REC') this.conditions.disable_values = true
      if (this.form.id && this.property._periods?._period) {
        if (this.property._periods._period >= this.form.end_month_id) this.conditions.months_locked = true
        if (this.property._periods._period >= this.form.init_month_id) this.conditions.disable_values = true
      }
    },

    // Modules

    addModule() {
      if (this.form.modules.length < 10) {
        // TODO: remove this
        // TODO: validate available modules
        if (this.can_add_module) this.form.modules.push({ id: 0 })
      }
      if (this.form.modules.length === 1) this.validateModules()
    },
    removeModule(index, module) {
      if (module.id) this.form.remove_modules = [...(this.form.remove_modules || []), ...[module.id]]
      this.form.modules.splice(index, 1)
      if (this.form.modules.length === 0) this.addModule() // this.removeValidator('modules')
    },
    validateModules() {
      this.$nextTick(() => {
        if (this.validators?.extra?.modules) this.injectValidator('modules', 0)
      })
    },

    // SUBMIT

    submit() {
      if (!this.status.is_valid) return

      this.setErrors({})
      this.$v.form.$touch()

      const alert = `There was an error ${this.form.id ? 'saving' : 'creating'} the property.`

      if (this.$v.form.$error) {
        this.locateErrors(alert)
        return
      }

      const self = this
      self.$http[self.form.id ? 'put' : 'post']('admin/budgets', self.form) // TODO: on service ?
        .then(response => {
          self.$router.replace(`/admin/property/budgets/${response.data.budget.id}/edit`).catch(() => {})
          self.showAlert(`Budget ${self.form.id ? 'saved' : 'created'}.`)
          self.parseData(response.data)
        })
        .catch(error => {
          // TODO: move to form helper ?
          //if (error && error.status === 422) {
          if (error.response && error.response.status === 422) {
            self.setErrors(error.response.data.errors || {})
          }
          if (error.response?.data?.message == 'The given data was invalid.') {
            self.showAlert(alert, 'danger')
            for (const key in error.response.data.errors) {
              if (error.response.data.errors[key]) {
                self.message += error.response.data.errors[key][0] + '  '
              }
            }
          } else {
            console.error(error)
            self.showAlert(alert, 'danger')
            //self.goBack() // TODO: login ?
          }
        })
    }
  }
}
</script>
